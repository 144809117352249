import React from "react";
import { createUseStyles } from "react-jss";

import Sidebar from "components/widgets/Sidebar";
import MapView from "views/MapViews";

const useStyles = createUseStyles((theme) => ({
	normalLayout: {
		width: "100%",
		height: "100%",
		display: "grid",
		gridTemplateAreas: "'sidebar map'",
		gridTemplateColumns: "366px 10fr",
	},
}));

const NormalLayout = ({ ...props }) => {
	const classes = useStyles();

	return (
		<div {...props} className={classes.normalLayout}>
			<MapView />
			<Sidebar />
		</div>
	);
};

export default NormalLayout;
