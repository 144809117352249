import React from "react";
import { FC } from "react";
import { FlagSvg } from "assets/icons";
import { createUseStyles } from "react-jss";
import theme from "styles/themes";

const useStyles = createUseStyles({
	wrapper: {
		width: "100%",
		borderBottom: "1px solid #F4F4F4",
		padding: "15px",
		fontFamily: theme.font.primary,
		color: "#4D4C4C",
		lineHeight: 1.4,
	},
	locationHeader: {
		marginBottom: 15,
		fontWeight: 400,
		fontSize: 15,
		lineHeight: 1.4,
		display: "flex",
		alignItems: "center",
		cursor: "pointer",
	},
	locationFlagIcon: {
		marginRight: 10,
		padding: "5px 0 0 0",
	},
	locationData: {
		fontWeight: 700,
		fontSize: 13,
		marginTop: 5,
		display: "flex",
		alignItems: "center",

		"& span": {
			"&:first-child": {
				flexBasis: 40,
			},
		},
	},
	locationDataValue: {
		flexBasis: 150,
		fontSize: 18,
		fontWeight: 500,
		fontFamily: theme.font.secondary,
		lineHeight: 1.1,
		padding: "6px 9px",
		borderRadius: 5,
		border: "0.5px solid #c7c7c7",
		color: theme.color.accent,
	},
});
interface LocationProps {
	index: number;
	lat: number;
	lng: number;
}

const Location: FC<LocationProps> = ({ index, lat, lng, children, ...otherProps }) => {
	const classes = useStyles();

	return (
		<div {...otherProps} className={classes.wrapper}>
			<div className={classes.locationHeader}>
				<span className={classes.locationFlagIcon}>
					<FlagSvg />
				</span>
				Location {index}
			</div>
			<div className={classes.locationData}>
				<span>Lat</span> <div className={classes.locationDataValue}>{lat}</div>
			</div>
			<div className={classes.locationData}>
				<span>Long</span> <div className={classes.locationDataValue}>{lng}</div>
			</div>
		</div>
	);
};

export default Location;
