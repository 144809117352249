import Circle from "ol/geom/Circle";
import Fill from "ol/style/Fill";
import Stroke from "ol/style/Stroke";
import Style from "ol/style/Style";
import { Feature } from "ol";
import { fromLonLat } from "ol/proj";

export const getMarker = (coordinates: Coordinates) => {
	const blueCircle = new Feature({
		geometry: new Circle(fromLonLat([coordinates[1], coordinates[0]]), 20),
	});
	blueCircle.setStyle(
		new Style({
			fill: new Fill({
				color: "rgba(255, 255, 255, 0)",
			}),
			stroke: new Stroke({
				color: "#3099CC",
				width: 3,
			}),
		})
	);
	return blueCircle;
};
