import React from "react";
import { createUseStyles } from "react-jss";

import NormalLayout from "components/layouts/NormalLayout";
import { MapProvider } from "contexts/MapContext";
import { LocationProvider } from "contexts/LocationContext";

const useStyles = createUseStyles((theme) => ({
	"@global": {
		"*": {
			boxSizing: "border-box",
		},
		body: {
			margin: 0,
			padding: 0,
		},
	},
	app: {
		width: "100vw",
		height: "100vh",
	},
}));

function App() {
	const classes = useStyles();
	return (
		<div className={classes.app}>
			<MapProvider>
				<LocationProvider>
					<NormalLayout />
				</LocationProvider>
			</MapProvider>
		</div>
	);
}

export default App;
