import React, { useContext, useEffect, useRef } from "react";
import { createUseStyles } from "react-jss";

import { mapContext } from "contexts/MapContext";

const useStyles = createUseStyles((theme) => ({
	map: {
		gridArea: "map",
		backgroundColor: "lightgreen",
	},
}));

const Map = () => {
	const classes = useStyles();
	const mapRef = useRef<any>();
	const { renderMapOn } = useContext(mapContext);

	useEffect(() => {
		renderMapOn(mapRef);
	}, []);

	return <div ref={mapRef} className={classes.map}></div>;
};

export default Map;
