import { createContext, FC, MutableRefObject, useEffect, useRef, useState } from "react";
import { Map as OLMap, View } from "ol";
import { fromLonLat } from "ol/proj";
import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";

import "ol/ol.css";

import MAP_CONSTANTS from "constants/map.constants";

const _defaultMap = new OLMap({
	view: new View(),
	layers: [
		new TileLayer({
			source: new OSM(),
		}),
	],
});

interface MapContextInterface {
	map: OLMap;
	renderMapOn: (ref: MutableRefObject<any>) => void;
}

export const mapContext = createContext<MapContextInterface>({
	map: _defaultMap,
	renderMapOn: () => {},
});

export const MapProvider: FC = ({ children }) => {
	const [map, setMap] = useState<OLMap>(_defaultMap);
	const [mapContainer, setMapContainer] = useState<MutableRefObject<any>>();

	useEffect(() => {
		const _map = new OLMap({
			view: new View({
				zoom: MAP_CONSTANTS.DEAFULT_ZOOM,
				center: fromLonLat(MAP_CONSTANTS.SEOUL_CITY_HALL_COORDINATES),
			}),
			layers: [
				new TileLayer({
					source: new OSM(),
					zIndex: 0,
					visible: true,
				}),
			],
		});
		setMap(_map);
		return () => map.setTarget(undefined);
	}, []);

	useEffect(() => {
		if (!map) return;
		if (!mapContainer) return;

		map.setTarget(mapContainer.current);
	}, [map, mapContainer]);

	return <mapContext.Provider value={{ map, renderMapOn: setMapContainer }}>{children}</mapContext.Provider>;
};
