import React from "react";
import { createUseStyles } from "react-jss";
import { useNavigate, useLocation } from "react-router-dom";

import tabsConfigs from "configs/tabConfigs";

import TabIcon from "./TabIcon";
import SidebarRoutes from "routes/Sidebar.routes";

const useStyles = createUseStyles({
	sidebar: {
		gridArea: "sidebar",
		backgroundColor: "white",
		display: "grid",
		gridTemplateColumns: "60px 10fr",
		gridTemplateAreas: "'tabs panel'",
	},
	tabs: {
		gridArea: "tabs",
		background: "white",
		boxShadow: "0 3px 6px rgba(40, 40, 40, .3)",
		padding: 5,
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		gap: 15,
	},
	panel: {
		gridArea: "panel",
	},
});

const Sidebar = () => {
	const navigate = useNavigate();
	const classes = useStyles();
	const location = useLocation();

	return (
		<div className={classes.sidebar}>
			<div className={classes.tabs}>
				{tabsConfigs.map((tab, index) => (
					<TabIcon
						key={"tab-" + index}
						active={location.pathname === tab.path}
						svg={<tab.icon />}
						onClick={(e) => navigate(tab.path)}
					/>
				))}
			</div>
			<div className={classes.panel}>
				<SidebarRoutes />
			</div>
		</div>
	);
};

export default Sidebar;
