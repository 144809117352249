import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import tabsConfigs from "configs/tabConfigs";
import LocationsView from "views/SidebarViews/Locations.view";

const SidebarRoutes = () => {
	return (
		<Routes>
			<Route index element={<Navigate to={"tab1"} />} />
			<Route path="/tab1" element={<LocationsView />} />
		</Routes>
	);
};

export default SidebarRoutes;
