import { useMap } from "hooks/useMap";
import { createContext, FC, useEffect, useState } from "react";
import { onGetLocations } from "services/locations.services";

const _defaultLocationContextValue: iLocation = {
	locations: [],
	status: "idle",
	error: "",
};

export const locationContext = createContext<iLocation>(_defaultLocationContextValue);

export const LocationProvider: FC = ({ children }) => {
	const [locationData, setLocationData] = useState<iLocation>(_defaultLocationContextValue);
	const { addMarker } = useMap();

	const getLocations = async () => {
		setLocationData({
			locations: [],
			status: "loading",
		});
		try {
			const { locations } = await onGetLocations();
			setLocationData({
				locations,
				status: "loaded",
			});
		} catch (error) {
			setLocationData({
				locations: [],
				status: "failed",
				error: (error as Error).message || "Something went wrong while loading location data.",
			});
		} finally {
		}
	};

	useEffect(() => {
		getLocations();
	}, []);

	useEffect(() => {
		if (!locationData.locations.length) return;
		addMarker(locationData.locations);
	});

	return <locationContext.Provider value={locationData}>{children}</locationContext.Provider>;
};
