const theme: Jss.Theme = {
	color: {
		primary: "yellow",
		secondary: "#C7C7C7",
		accent: "#1F4782",
	},
	font: {
		primary: "'Noto Sans KR', sans-serif",
		secondary: "'Roboto', sans-serif",
	},
	transition: {
		duration: ".2s",
		timing: "ease-out",
	},
};

export default theme;
