import { LocationSvg, ScaleSvg, LayerSvg } from "assets/icons";

const tabsConfigs = [
	{
		icon: LocationSvg,
		path: "/tab1",
	},
	{
		icon: ScaleSvg,
		path: "/tab2",
	},
	{
		icon: LayerSvg,
		path: "/tab3",
	},
];

export default tabsConfigs;
