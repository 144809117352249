import React from "react";
import { FC } from "react";
import { createUseStyles } from "react-jss";

interface TabIconProps {
	svg?: JSX.Element;
	active?: boolean;
	onClick?: (event: React.MouseEvent) => void;
}

const useStyles = createUseStyles<"tabIcon", { active?: boolean }>((theme) => ({
	tabIcon: {
		height: 44,
		width: 44,

		borderRadius: 5,
		overflow: "hidden",

		cursor: "pointer",

		display: "flex",
		justifyContent: "center",
		alignItems: "center",

		border: 0,
		color: (props) => (props.active ? "white" : theme.color?.secondary || "grey"),
		backgroundColor: (props) => (props.active ? theme.color?.accent || "blue" : "white"),
		transitionDuration: theme.transition?.duration || ".2s",
		transitionTimingFunction: theme.transition?.timing || "ease-out",
	},
}));

const TabIcon: FC<TabIconProps> = ({ svg, active, onClick, children, ...otherProps }) => {
	const classes = useStyles({ active });

	return (
		<button {...otherProps} className={classes.tabIcon} onClick={(e) => onClick && onClick(e)}>
			{svg ? svg : children}
		</button>
	);
};

export default TabIcon;
