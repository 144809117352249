import React from "react";
import { useLocations } from "hooks/useLocations";
import { createUseStyles } from "react-jss";
import Location from "components/widgets/Location";

const useStyles = createUseStyles(() => ({
	locationsView: {},
}));

const LocationsView = () => {
	const classes = useStyles();
	const { locations, status, error } = useLocations();

	return (
		<div className={classes.locationsView}>
			{status === "idle" || status === "loading" ? <></> : null}
			{status === "failed" ? <p>{error}</p> : null}
			{status === "loaded" ? (
				<>
					{locations.map((location, index) => (
						<Location key={`location-description-${index}`} index={index + 1} lat={location[0]} lng={location[1]} />
					))}
				</>
			) : null}
		</div>
	);
};

export default LocationsView;
