import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";

import { useContext } from "react";
import { mapContext } from "contexts/MapContext";

import { getMarker } from "utils/map.utils";

export const useMap = () => {
	const { map } = useContext(mapContext);

	const addMarker = (coordinates: Coordinates[]) => {
		map.addLayer(
			new VectorLayer({
				source: new VectorSource({
					features: coordinates.map((coordinate) => getMarker(coordinate)),
				}),
				visible: true,
				zIndex: 19,
			})
		);
	};

	return { map, addMarker };
};
